/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='text'],
textarea,
select {
  font-size: 16px;
}

body {
  margin: 0 0.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;

  // -webkit-tap-highlight-color: transparent;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;

  :focus {
    outline: none !important;
  }
}

#root {
  max-width: 600px;
}

em {
  font-weight: bold;
}

.app {
  margin-bottom: 80px;
}

h2 {
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;

  &.section {
    border-bottom: 1px solid #c6c6c6;
    padding-bottom: 4px;
  }
}

h3 {
  font-size: 1.1rem;
  margin: 1.5rem 0 0.5rem 0;
  font-weight: bold;
}

/* fix svg buttons */
svg {
  pointer-events: none;
}

/* fix snackbar button */
button {
  border: none;
  color: white;
}

button.button-wide,
.button a {
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  margin: 0.75rem 0;
  padding: 0.75rem;

  &:hover {
    border: 1px solid #aaa;
    background-color: #d8d8d8;
  }
}

button.button-wide {
  width: 100%;
  font-size: 1rem;
}

a {
  &.btn {
    position: absolute;
    right: 0;
    top: -0.2rem;
  }
}

#login {
  margin: 1rem;

  h1 {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .form {
  }

  .row {
    display: flex;
  }
}

#logo {
  text-align: center;
  padding: 1.5rem 0 1rem 0;

  img {
    max-width: 50%;
  }
}

#error {
  font-weight: 500;
  text-align: center;
  line-height: 140%;

  a {
    text-decoration: none;
  }
}

ul#navi {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  border-top: 1px solid #555;
  background-color: white;

  li {
    flex: 1;
    list-style-type: none;
    text-align: center;
    border-right: 1px solid #555;

    a {
      display: block;
      color: #999;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.8rem;
      padding: 0.5rem 0 0.5rem 0;

      &.active {
        color: #000;
      }
    }
  }
}

input,
select {
  font-size: 1rem;
  border: none;
  padding: 0.25rem 0 1px 0.25rem;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

ul.stock-list {
  li {
    &.total {
      font-weight: 500;
    }

    div {
      flex: 1;

      &.product-bottles,
      &.product-price {
        text-align: right;
      }
    }
  }
}

ul.stock-list,
ul.product-list {
  margin-top: 1.5rem;

  li {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8rem;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0 0.5rem 0;
    line-height: 1.2rem;

    &.header {
      font-weight: bold;
      padding: 0 0 0.25rem 0;
      margin: 0;

      border-bottom: 1px solid #555;
      cursor: pointer;
    }
  }
}

ul.product-list {
  &.noborder {
    li {
      border: none;
    }
  }

  li {
    &.large {
      font-size: 1rem;

      padding: 0.5rem 0.25rem 0 0.5rem;

      input {
        padding: 0 0 0.25rem 0.25rem;
        margin: 0;
      }
    }

    &.odd,
    &.odd input {
      background-color: #f8f8f8;
    }

    &:last-child {
      border: none;
    }

    &.header {
      .product-bottles-input {
        text-align: center;
      }
    }

    &.empty {
      font-style: italic;
      margin-bottom: 0.25rem;
      border: none;
    }

    div {
      &.product-id {
        flex: 30;
      }

      &.product-name {
        flex: 46;
      }

      &.product-alert {
        width: 20px;
        color: #db0d0d;
      }

      &.product-add,
      &.product-rtls,
      &.product-bottles,
      &.product-bottles-input,
      &.product-retailSize {
        flex: 12;
        text-align: right;
      }

      &.product-bottles {
        padding-right: 0.4rem;
      }

      &.product-warehouse {
        flex: 8;
        text-align: center;
      }

      &.product-bottles-input {
        margin-left: 1rem;
      }

      &.product-comment {
        flex-basis: 100%;

        input {
          width: 100%;
        }
      }

      a {
        color: black;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

p.product-total {
  padding-top: 6px;
  font-size: 0.8rem;
  text-align: right;

  em {
    font-weight: bold;
  }
}

#order {
  margin-bottom: 10rem;

  ul.product-list {
    margin-top: 0;
  }

  input.filter {
    width: calc(100% - 0.5rem);
    border: 1px solid #ccc;
    padding: 0.5rem 0.25rem;
  }
}

.form {
  margin: 0.75rem 0 0 0;

  .row {
    display: flex;
    margin: 0 0 0.5rem 0;

    .hdr {
      flex: 1;
      font-weight: 500;
      text-align: right;
      padding: 0.25rem 0.5rem 1px 0;
    }

    .checkbox {
      padding: 0.25rem 0.5rem 0 20%;

      label {
        padding: 0 0.5rem 0 0.5rem;
      }
    }

    .val {
      flex: 4;

      input,
      select {
        width: 100%;
      }

      input[type='radio'],
      input[type='checkbox'] {
        width: auto;
      }
    }
  }
}

@media (min-width: 600px) {
  body {
    background-color: #e8e8e8;
  }

  .app {
    margin-bottom: 0;
    padding-bottom: 2rem;
    position: relative;
  }

  #root {
    margin: 0 auto;
    padding: 0 1.5rem;
    background-color: #fff;
    border-left: 1px solid #cacaca;
    border-right: 1px solid #cacaca;
  }

  #logo {
    padding-top: 4.5rem;
  }

  #order {
    margin-bottom: 0;
  }

  ul#navi {
    position: absolute;
    top: 0;
    bottom: auto;
    border: none;
    padding: 1rem 0 0.5rem 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;

    li {
      border: none;

      a {
        font-size: 1rem;

        i {
          display: none !important;
        }
      }
    }
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 1.5rem;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px 3px;
  font-weight: 500;
  font-size: 0.9rem;
  border-bottom: 1px solid #aaa;

  &:last-child {
    border-bottom: none;
  }

  span {
    font-weight: 400;
    font-size: 0.85rem;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-datepicker-wrapper {
  display: block;
}

.history {
  margin-top: 1rem;

  .entry {
    margin-bottom: 4em;
  }

  .count {
    min-width: 4rem;
    text-align: right;

    a {
      color: black;
    }
  }

  .orderItem {
    margin-bottom: 2rem;

    .header {
      background-color: #ececec;
      padding: 0.25rem;
      margin-bottom: 0.25rem;
      display: flex;
      font-weight: bold;
      line-height: 1.4rem;

      .date {
        flex-grow: 1;
        text-align: right;
        font-weight: normal;
      }

      .actions {
        flex-grow: 1;
        text-align: right;
        display: flex;
        justify-content: flex-end;

        div {
          flex: 1;
          max-width: 30px;
          margin-left: 1rem;

          &.inactive {
            color: #c6c6c6;
          }
        }
      }
    }
  }

  .detailWrap {
    display: flex;

    .left {
      flex-grow: 1;
    }

    .icon {
      cursor: pointer;
      margin-right: 1rem;
    }

    a {
      color: black;
    }
  }

  .details {
    display: flex;
    margin: 0.4rem 0;

    div {
      flex-grow: 1;
      line-height: 140%;
    }

    span {
      font-weight: 500;
    }
  }

  ul {
    margin-top: 0.75rem;

    li {
      display: flex;
      margin-bottom: 0.5rem;

      &.hdr {
        font-weight: bold;
        padding-bottom: 0.25rem;
        border-bottom: 1px solid #aaa;
      }

      // &.arrival {
      //   .id {
      //     flex: 2;
      //   }

      //   .name {
      //     flex: 6;
      //   }

      //   .bottles {
      //     flex: 1;
      //     text-align: right;
      //   }
      // }

      div {
        flex-grow: 1;

        &.id {
          flex: 25%;
        }

        &.name {
          flex: 50%;
          padding-left: 0.3rem;
        }
      }
    }
  }
}

.sales-report {
  select {
    margin-right: 1rem;

    &.year {
      min-width: 5rem;
    }

    &.month {
      min-width: 3rem;
    }
  }

  a {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .link {
    margin-top: 1rem;
  }
}

.calculator {
  .wrap {
    padding: 0.5rem;
    display: flex;

    .col {
      flex: 33%;
    }
  }
  p {
    padding: 0.5rem;
  }
}
